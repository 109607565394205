import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Header from '../components/Header/Header'
import JobStackItem from '../components/JobStackItem/JobStackItem'
import Button from '../components/Button/Button'

const JobsPage = ({ data }) => {
  const jobs = data.allContentfulJob.edges

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className='mb-6'>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className='pl-10 mb-6'>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className='list-item-with-arrow pl-4'>{children}</li>
      }
  }

  return (
    <Layout>
      <SEO title='Jobs' />
      <Header titleBig='Ein neuer Job für dich?' color='skyblue' />
      <div className='flex justify-center px-6'>
        <div className='flex flex-col max-w-4xl flex-wrap py-20 w-full'>
          <p className='mb-4 font-headline leading-relaxed'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
          <Button to='/about' withArrow className='mb-24'>mehr zu uns</Button>
          <h2 className='mb-16'>Aktuelle Jobangebote bei swenden:</h2>
          <div>
            {jobs.map((job, index) => {
              return (
                <JobStackItem
                  key={index}
                  title={job.node.title}
                  slug={job.node.slug}
                  className={index === jobs.length-1 ? 'border-b border-black' : ''}
                />
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JobsPage

export const jobsQuery = graphql`
  query {
    allContentfulJob {
      edges {
        node {
          title
          slug
          childContentfulJobContentMiniVersionRichTextNode {
            json
          }
        }
      }
    }
  }
`
