import React, { useState } from 'react'

import Button from '../Button/Button'

import ArrowRight from '../../images/svgs/cta-arrow-right.svg'

const JobStackItem = (props) => {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <div className={`${props.className} `}>
      <div className='border-t border-black cursor-pointer py-6' onClick={() => toggleExpansion(!isExpanded)} >
        <ArrowRight className={`stroke-current hover:stroke-current w-8 inline mr-2 transition ${isExpanded ? 'rotate-90' : ''}`} />
        <span className=''>{props.title}</span>
      </div>
      <div className={`w-full justify-center border-t border-black ${isExpanded ? 'flex' : 'hidden'}`}>
        <div className='flex flex-col py-16 max-w-3xl w-full'>
          <h2 className='mb-6'>Wir suchen: {props.title}</h2>
          {props.content}
          <Button to={`jobs/${props.slug}`} withArrow className=''>
            mehr erfahren
          </Button>
        </div>
      </div>
    </div>
  )
}

export default JobStackItem
